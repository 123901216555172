import React from 'react';
import { Link } from 'react-router-dom';
import mindlineIcon from '../assets/mla_icon.png';

const HeaderIcon = () => (
  <Link className="navbar-brand" to={`/`}>
    <img src={mindlineIcon} height="35px" alt="mindline analytics" />
  </Link>
);

export default HeaderIcon;
