import React from 'react';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { MultiSlider } from './multi-slider';
import { RowLabel } from './RowLabel';

export const SingleSliderRow = props => {
  const {
    title,
    name,
    value = [50],
    change = () => console.log('Info: no change handler specified.'),
    tooltip = null,
    min = 0,
    max = 100,
    step = 1
  } = props;

  return (
    <FormGroup row>
      <RowLabel name={name} title={title} tooltip={tooltip} />

      <Col lg={8} xl={9}>
        <MultiSlider
          onChange={change}
          handleSize={20}
          values={value}
          min={min}
          max={max}
          minDistance={10}
        />
      </Col>
    </FormGroup>
  );
};

SingleSliderRow.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  change: PropTypes.func,
  tooltip: PropTypes.string
};
