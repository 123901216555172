import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import Track from './Track';
import Handle from './Handle';
import './styles.css';

/**
 *
 *
 * @param onChange
 * @param values
 * @returns {*}
 * @constructor
 *
 * @todo: add window resize listener to render new width
 */
const MultiSlider = ({
  onChange,
  values,
  min,
  max,
  handleSize = 20,
  trackSize = 5,
  minDistance = 1
}) => {
  const svg = useRef(null);

  const useTouches = typeof window !== 'undefined' && 'ontouchstart' in window;
  const [internalValues, setInternalValues] = useState(values);

  const [handleActive, setHandleActive] = useState(null);
  const [clientRect, setClientRect] = useState(null);
  const [tracks, setTracks] = useState(null);
  const [handles, setHandles] = useState(null);

  let colors = ['#c51a1b', '#8aa91b', '#e39700', '#5e82b9'];

  useEffect(() => {
    setTracks(getTracks());
    setHandles(getHandles());
  }, [clientRect, values]);

  useEffect(() => {}, [tracks, handles]);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateClientRect);
    updateClientRect();
    return () => window.removeEventListener('resize', updateClientRect);
  }, []);

  const updateClientRect = () => {
    if (svg.current) {
      const clientRect = svg.current.getBoundingClientRect();
      setClientRect(clientRect);
    }
  };

  const onHandleMove = (index, x) => {
    const d3ScaleInverted = d3.scale
      .linear()
      .rangeRound([min, max])
      .domain([0, clientRect.width - handleSize])
      .clamp(true);

    const value = d3ScaleInverted(x);

    if (
      // check if position runs out of parent
      x <= 0 ||
      x >= clientRect.width - handleSize ||
      // check if element collides with siblings (up and/or down)
      (index === 0 &&
        values.length > 0 &&
        value + minDistance >= values[index + 1]) ||
      (index === values.length - 1 &&
        values.length > 0 &&
        value - minDistance <= values[index - 1]) ||
      (index > 0 &&
        index < values.length - 1 &&
        (value + minDistance >= values[index + 1] ||
          value - minDistance <= values[index - 1]))
    )
      return false;

    values[index] = value;
    onChange([...values]);

    return true;
  };

  const getTracks = () => {
    if (!clientRect) return;
    const d3Scale = d3.scale
      .linear()
      .domain([min, max])
      .range([0, clientRect.width - handleSize]);

    let tracks = [];

    if (values[0] > min) {
      tracks.push(
        <Track
          key={'start_track'}
          x1={0}
          x2={d3Scale(values[0])}
          y={handleSize / 2}
          color={'#9a9da4'}
          size={trackSize}
        />
      );
    }

    values.map((value, index) => {
      if (index === values.length - 1) return;
      const x1 = d3Scale(value);
      const x2 = d3Scale(values[index + 1]);
      tracks.push(
        <Track
          key={index}
          label={`${value} to ${values[index+1]}`}
          x1={x1}
          x2={x2}
          y={handleSize / 2}
          color={'#8aa91b'}
          size={trackSize}
        />
      );
    });

    if (values[values.length - 1] < max) {
      tracks.push(
        <Track
          key={'end_track'}
          x1={d3Scale(values[values.length - 1])}
          x2={d3Scale(max)}
          y={handleSize / 2}
          color={'#9a9da4'}
          size={trackSize}
        />
      );
    }

    return tracks;
  };

  const getHandles = () => {
    if (!clientRect) return;
    const d3Scale = d3.scale
      .linear()
      .domain([min, max])
      .range([0, clientRect.width - handleSize]);

    const handles = values.map((value, index) => {
      const x = d3Scale(value);
      const events = {
        onHandleMove
      };

      return (
        <Handle
          key={`handle${index}`}
          id={index}
          value={value}
          events={events}
          x={x}
          size={handleSize}
          color={'#8aa91b'}
        />
      );
    });
    return handles;
  };

  return (
    <svg ref={svg} width={'100%'} height={`${handleSize * 2}px`}>
      {/*<defs>*/}
        <symbol id={'plus-icon'} viewBox={'0 0 55 55'}>
          <path d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26 S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z" />
          <path d="M38.5,25H27V14c0-0.553-0.448-1-1-1s-1,0.447-1,1v11H13.5c-0.552,0-1,0.447-1,1s0.448,1,1,1H25v12c0,0.553,0.448,1,1,1 s1-0.447,1-1V27h11.5c0.552,0,1-0.447,1-1S39.052,25,38.5,25z" />
        </symbol>
      {/*</defs>*/}

      <g
        id={'track'}
        className={'track'}
        transform={`translate(${handleSize / 2},${handleSize / 2})`}
      >
        {tracks}
      </g>
      <g
        id="handle"
        transform={`translate(${handleSize / 2},${handleSize / 2})`}
      >
        {handles}
      </g>
    </svg>
  );
};

MultiSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleSize: PropTypes.number,
  trackSize: PropTypes.number,
  minDistance: PropTypes.number
};

export default MultiSlider;
