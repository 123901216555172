import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userActions } from '../actions/user.actions';

import { BaseLayout } from '../../common/components/layouts';

const Logout = props => {
  const history = useHistory();

  if (props.authentication.loggedIn && !props.authentication.loggingOut) {
    props.logout();
  } else if (
    props.authentication.loggedOut ||
    props.authentication.loggingOut
  ) {
    setTimeout(() => history.push('/login'), 2000);
  } else if (
    !props.authentication.loggedIn &&
    !props.authentication.loggedOut &&
    !props.authentication.loggingOut
  ) {
    history.push('/login');
  }

  return (
    <BaseLayout>
      <div className="base-content d-flex flex-fill container-fluid">
        <div>sie werden abgemeldet...</div>
        {/*{ props.authentication.loggedOut || props.authentication.loggingOut &&  }*/}
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = state => ({
  authentication: state.authentication
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(userActions.logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
