import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';
import 'core-js/fn/array/includes';
import 'core-js/fn/array/fill';
import 'core-js/fn/array/from';
import 'core-js/fn/string/pad-start';
import 'core-js/fn/object/values';
import 'core-js/fn/symbol';
import 'core-js/fn/symbol/iterator';

import React from 'react';
import { render } from 'react-dom';
import { connect, Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import * as Sentry from '@sentry/browser';

import { store } from './core/store';

import './core/axiosConfig';

import './core/assets/css/app.css';
import { BaseLayout } from './core/pages/BaseLayout';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    release: 'datev-eva@0.0.2',
    dsn: 'https://dded3ff1c0974d1f9a8a68df72d088df@sentry.io/1400368'
  });
}
function App(props) {
  return (
    <Provider store={props.store}>
      <PersistGate
        persistor={store.persistor}
        loading={<div>...rehydrating</div>}
      >
        <BaseLayout locale={props.locale} />
      </PersistGate>
    </Provider>
  );
}

const mapStateToProps = state => ({
  locale: state.settings.locale
});

const ConnectedApp = connect(mapStateToProps, null)(App);

render(<ConnectedApp store={store} />, document.getElementById('root'));
