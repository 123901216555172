import { persistReducer } from 'redux-persist';
import session from 'redux-persist/lib/storage/session';

import { userConstants } from '../constants';

const INITIAL_STATE = {
  loggedIn: false,
  loggingIn: false,
  loggingOut: false,
  loggedOut: false,
  captcha: false,
  access: false,
  error: null
};

const authenticationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'USER_SESSION_EXPIRED':
      return {
        ...INITIAL_STATE,
        error: 'Sitzung abgelaufen, bitte melden Sie sich neu an.'
      };

    case userConstants.LOGIN_CAPTCHA_REQUIRED:
      return {
        ...state,
        captcha: true
      };

    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        captcha: false,
        user: action.user,
        error: null
      };

    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        loggingIn: false,
        loggedIn: true,
        access: action.user.toolbox.navi.actions
      };

    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        error: action.error
      };

    case userConstants.REMEMBER_LOGIN_REQUEST:
      return {
        ...state,
        loggingOut: false,
        loggedOut: false,
        loggingIn: true
      };

    case userConstants.REMEMBER_LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        loggingIn: false,
        loggedIn: true,
        user: action.user.username || false,
        access: action.user.toolbox.hasOwnProperty('navi')
          ? action.user.toolbox.navi.actions
          : action.user.toolbox.navigation.actions
      };

    case userConstants.REMEMBER_LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false
      };

    case userConstants.LOGOUT_REQUEST:
      return {
        ...state,
        loggingOut: true
      };

    case userConstants.LOGOUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        loggedOut: true
      };

    case userConstants.LOGOUT_FAILURE:
      return {
        ...state,
        loggedOut: false,
        loggingOut: false,
        error: 'Logout konnte nicht abgeschlossen werden'
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'authentication',
  storage: session,
  blacklist: ['error', 'loggingIn', 'loggedOut', 'loggingOut']
};

export const authentication = persistReducer(
  persistConfig,
  authenticationReducer
);
