import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

const Handle = ({ id, value, events, x, size, color }) => {
  const circle = useRef(null);

  const drag = d3.behavior.drag().on('drag', dragmove);

  function dragmove(d) {
    const { x } = d3.event;
    const handle = d3.select(circle.current);

    if (events.onHandleMove(id, x)) {
      handle.attr('transform', `translate(${x},${size / 2})`);
      handle.select('text').text(value);
    }

    return;
  }

  useEffect(() => {
    if (circle.current) {
      d3.select(circle.current).call(drag);
    }
  });

  return (
    <g transform={`translate(${x},${size / 2})`}
       ref={circle}
       draggable={true}
    >
      <circle
        r={size / 2}
        fill={'#ffffff'}
        stroke={color}
        strokeWidth={2}
        // onMouseDown={handle.bind(null, id)}
      />

      <g>
        <text x={0} y={1}>{value}</text>
      </g>

    </g>
  );
};

Handle.propTypes = {
  x: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
};

export default Handle;
