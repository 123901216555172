import produce from 'immer';
import { coreMessages } from './locales';
import { commonMessages } from '../../common/translation/locales';

const messages = registerMessages(commonMessages, coreMessages);

function registerMessages(newMessages, currentMessages = messages) {
  return produce(currentMessages, draftMessages => {
    Object.keys(newMessages).forEach(locale => {
      draftMessages[locale] = {
        ...currentMessages[locale],
        ...newMessages[locale]
      };
    });
  });
}

//@todo: implement unregister method to free space on onmount of DynamicImport Component?
const unregisterMessages = function(messages) {
  // const keys = Object.keys(messages);
  // for (let i = 0; i < keys.length; i++) {
  //   this.messages[keys[i]] = {
  //     ...this.messages[keys[i]],
  //     ...messages[keys[i]]
  //   };
  // }
};

export const i18n = {
  messages: messages,
  registerMessages,
  unregisterMessages
};
