import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { FormattedMessage } from 'react-intl';
import { settingsActions } from '../../../core/actions';

import { BaseHeaderLogo } from './';

import GermanFlag from '../../assets/img/country/de_DE.png';
import USFlag from '../../assets/img/country/en_US.png';

export const BaseHeaderPublic = ({ setLocale, locale }) => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerRef = useRef();

  const handleScroll = () => {
    if (window.pageYOffset > 30) {
      headerRef.current.classList.add('shrinked');
      headerRef.current.classList.add('box-shadow');
    } else {
      headerRef.current.classList.remove('shrinked');
      headerRef.current.classList.remove('box-shadow');
    }
  };

  return (
    <div
      className='base-header d-flex container-fluid fixed-top'
      ref={headerRef}
    >
      <nav className='navbar navbar-expand w-100'>
        {/* Logo */}
        <Link className='navbar-brand mr-auto' to={`/one`}>
          <BaseHeaderLogo type={'full'} />
        </Link>

        {/* Language */}
        <div className='navbar-nav'>
          <span
            className={`nav-item pointer ${locale === 'de-DE' ? 'active' : ''}`}
            onClick={() => setLocale('de-DE')}
          >
            <img src={GermanFlag} height='35px' alt={'Deutsch'} />
          </span>

          <span
            className={`nav-item pointer ${locale === 'en-US' ? 'active' : ''}`}
            onClick={() => setLocale('en-US')}
          >
            <img src={USFlag} height='35px' alt={'English'} />
          </span>
        </div>
      </nav>
    </div>
  );
};

BaseHeaderPublic.propTypes = {};

const mapStateToProps = state => ({
  locale: state.settings.locale
});

const mapDispatchToProps = dispatch => ({
  setLocale: locale => dispatch(settingsActions.setLocale(locale))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseHeaderPublic);
