import React from 'react';
import PropTypes from 'prop-types';
import { Col, CustomInput, FormGroup } from 'reactstrap';
import { RowLabel } from './RowLabel';

export const CellsCasesRow = props => {
  const {
    title,
    name,
    value,
    change = () => console.log('Info: no change handler specified.'),
    placeholder = null,
    tooltip = null,
    required = false
  } = props;

  return (
    <FormGroup row>

      <RowLabel name={name} title={title} tooltip={tooltip} />

      <Col lg={8} xl={9}>

        <div className='row'>

          {/*TEST CELLS - NUMERIC SELECT (1-8) */}
          <div className='col-6'>
            <CustomInput
              id='cellsCount'
              type='number'
              autoComplete='off'
              required
              className='question'
              value={value.cells}
              onChange={change.cells}
              step={1}
              min={1}
              max={8}
            />
            <label htmlFor='cellsCount'>
              <span>Number of cells</span>
            </label>
          </div>

          {/*NUMBER OF CASES PER CELL - NUMERIC */}
          <div className='col-6'>
            <CustomInput
              id='cases'
              type='number'
              autoComplete='off'
              required
              className='question'
              value={value.cases}
              onChange={change.cases}
              step={10}
              min={50}
              max={1000}
            />
            <label htmlFor='cases'>
              <span>Cases per cell</span>
            </label>
          </div>
        </div>

      </Col>
    </FormGroup>
  );
};

CellsCasesRow.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  change: PropTypes.func,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string
};
