import axios from 'axios';
import { history, store } from './store';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 30000;

axios.interceptors.response.use(
  response => response,
  error => {
    // Do something with response error
    const { status } = error.response;

    if (status === 401) {
      //@Todo: Check if just an unauthorised method or session expired by calling User/Home/index?
      store.dispatch({ type: 'USER_SESSION_EXPIRED' });
      history.push('/login');
    }

    return Promise.reject(error);
  }
);
