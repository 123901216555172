import { BaseLayout } from './BaseLayout';
import BaseHeader from './BaseHeader';
import BaseHeaderPublic from './BaseHeaderPublic';
import { BaseContent } from './BaseContent';
import { BaseFooter } from './BaseFooter';
import { BaseHeaderLogo } from './BaseHeaderLogo';

export {
  BaseLayout,
  BaseHeaderPublic,
  BaseHeader,
  BaseContent,
  BaseHeaderLogo,
  BaseFooter
};
