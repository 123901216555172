import { settings } from './settings';
import { routes } from './routes.reducer';
import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { profile } from './profile.reducer';
// import { registration } from './registration.reducer';
// import { users } from './users.reducer';

export const coreReducers = {
  settings,
  routes,
  authentication,
  profile,
  alert
};
