import React from 'react';
import { oneOfType, number, string, array } from 'prop-types';

const Track = ({ label = '', x1, x2, y, size, color }) => {
  return (
    <g>
      <line
        x1={x1}
        y1={y}
        x2={x2}
        y2={y}
        strokeWidth={size}
        stroke={color}
        strokeLinecap={'round'}
      />
      <rect
        onMouseEnter={() => {
          console.log('Enter');
        }}
        x={x1}
        y={0}
        width={x2 - x1}
        height={y * 2}
        fill={'transparent'}
      />

      <g>
        <text x={x1+(x2-x1)/2} y={5}>{label}</text>
      </g>

      {/*<use xlinkHref='#plus-icon' x='50' y='50' />*/}
    </g>
  );
};

Track.propTypes = {
  x1: oneOfType([number, string]).isRequired,
  x2: oneOfType([number, string]).isRequired,
  y: oneOfType([number, string]).isRequired,
  size: oneOfType([number, string]).isRequired,
  color: oneOfType([string, array]).isRequired
};

export default Track;
