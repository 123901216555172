import { connect } from 'react-redux';
import { withRouter } from 'react-router';
// import { navigateToScreen } from '../actions/creators';
import Navigation from '../components/Navigation';
import { userActions } from '../actions/user.actions';

const mapStateToProps = (state, props) => ({
  routes: state.routes,
  subRoutes: state.subRoutes,
  access: state.authentication.access
});

// const mapDispatchToProps = dispatch => ({
// navigate: path => {
// dispatch(navigateToScreen(path));
// dispatch(path);
// }
// });

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(userActions.logout())
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Navigation)
);
