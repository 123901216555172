import React from 'react';
import { BaseLayout } from '../../common/components/layouts';

const ImprintRoute = () => (
  <BaseLayout>
    <div className='base-content d-flex flex-fill container-fluid'>
      <div className='col-md-6 offset-md-3'>
        <div className='card bg-light text-black-50 mt-3'>
          <h4 className='card-header'>Impressum</h4>

          <div className='card-body'>
            <div className='row'>
              <div className='col-4'>
                <p className='small'>Verantwortlich für den Inhalt:</p>
              </div>
              <div className='col-8'>
                <p>
                  <b>mindline analytics GmbH</b>
                </p>
              </div>
            </div>

            <div className='row'>
              <div className='col-4'>
                <p className='small'>Geschäftsführer:</p>
              </div>
              <div className='col-8'>
                <p>Michael Briem, Stephan Winkelmann</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-4'>
                <p className='small'>Anschrift:</p>
              </div>
              <div className='col-8'>
                <p>
                  Nordostpark 45
                  <br />
                  D-90411 Nürnberg
                </p>
              </div>
            </div>

            <div className='row'>
              <div className='col-4'>
                <p className='small'>Kontakt:</p>
              </div>
              <div className='col-8'>
                <p>
                  Fon: +49 (0)911 - 95 64 98 - 0<br />
                  Fax: +49 (0)911 - 95 64 98 - 29
                  <br />
                  Web: www.mindline-analytics.de
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
);

export default ImprintRoute;
