import React, { useEffect, useState } from 'react';
import { LoginLayout } from '../pages/LoginLayout';
import { connect } from 'react-redux';
import { profileActions } from '../actions';

import { FormGroup, FormGroupInputText } from '../../common/components/forms';
import { Button, ButtonGroup } from './../../common/components/buttons';

function ProfileRoute(props) {
  const [submitted, setSubmitted] = useState(false);
  const [pw, setPw] = useState('');
  const [pwn1, setPwn1] = useState('');
  const [pwn2, setPwn2] = useState('');

  // Load initial edit data for the current user
  useEffect(() => {
    // let isCanceled = false;

    const getData = async () => {
      await props.edit();
      // const result = await props.edit();
    };

    getData();

    return () => {
      // isCanceled = true;
    };
  }, []);

  // Update component on error
  // Update component on message (success)
  useEffect(() => {
    // setSubmitted(false)
  }, [props.error, props.messages]);

  const handleSubmit = e => {
    e.preventDefault();
    if (pw.length === 0 || pwn1.length === 0 || pwn2.length === 0) {
      return false;
    }

    setSubmitted(true);

    props
      .changepassword(pw, pwn1, pwn2)
      .then(resolve => {
        setPw('');
        setPwn1('');
        setPwn2('');
        setSubmitted(false);

        // setTimeout(()=>{
        //   handleHistoryBack();
        // }, 5000 )
      })
      .catch(err => {});

    return false;
  };

  const handleChange = (e, setter) => {
    setSubmitted(false);
    setter(e.target.value);
  };

  const handleHistoryBack = e => {
    if (e) {
      e.preventDefault();
    }

    props.history.goBack();
  };

  if (props.loading) {
    return (
      <LoginLayout>
        <div>loading...</div>
      </LoginLayout>
    );
  } else {
    return (
      <LoginLayout>
        <div className='bg-content'>
          <div
            className='row m-0 bg-breadcrump align-items-center text-white'
            style={{ minHeight: 50 }}
          >
            <div className='font-weight-bold pl-3 mr-auto'>
              Profil-Einstellungen
            </div>
          </div>

          <div className='card-body'>
            <div className='jumbotron mt-3 p-3'>
              <form noValidate={true}>
                <h4>
                  Um das Passwort Ihres Zugangs zu ändern, geben Sie bitte Ihr
                  aktuelles Passwort sowie zweifach das gewünschte neue Passwort
                  ein.
                  {/*To change the password of your account, please enter your current password as well as your new password twice.*/}
                </h4>

                {/*<p id="passwordHelpBlock" className="form-text text-muted">*/}
                {/*Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces,*/}
                {/*special characters, or emoji.*/}
                {/*</p>*/}

                <FormGroupInputText
                  label={'Aktuelles Passwort:'}
                  name={'password_old'}
                  autocomplete={'current-password'}
                  type={'password'}
                  placeholder={'aktuelles Passwort'}
                  value={pw}
                  onChangeFn={e => handleChange(e, setPw)}
                  submitted={submitted}
                  invalidText={
                    props.error &&
                    props.error.pw &&
                    Object.keys(props.error.pw).map(key => (
                      <React.Fragment key={key}>
                        <span>{props.error.pw[key]}</span>
                        <br />
                      </React.Fragment>
                    ))
                  }
                />

                <FormGroupInputText
                  label={'Neues Passwort:'}
                  name={'password_new1'}
                  autocomplete={'new-password'}
                  type={'password'}
                  placeholder={'neues Passwort'}
                  value={pwn1}
                  onChangeFn={e => handleChange(e, setPwn1)}
                  submitted={submitted}
                  invalidText={
                    props.error &&
                    props.error.pw1 &&
                    Object.keys(props.error.pw1).map(key => (
                      <React.Fragment key={key}>
                        <span>{props.error.pw1[key]}</span>
                        <br />
                      </React.Fragment>
                    ))
                  }
                />

                <FormGroupInputText
                  label={'Wiederholung neues Passwort:'}
                  name={'password_new2'}
                  autocomplete={'new-password'}
                  type={'password'}
                  placeholder={'Wiederholung neues Passwort'}
                  value={pwn2}
                  onChangeFn={e => handleChange(e, setPwn2)}
                  submitted={submitted}
                  invalidText={
                    props.error &&
                    props.error.pw2 &&
                    Object.keys(props.error.pw2).map(key => (
                      <React.Fragment key={key}>
                        <span>{props.error.pw2[key]}</span>
                        <br />
                      </React.Fragment>
                    ))
                  }
                />

                {props.messages && (
                  <FormGroup>
                    <h6 className={'text-success mt-3'}>
                      {props.messages.map(msg => msg.message).join('<br />')}
                    </h6>
                  </FormGroup>
                )}

                <FormGroup className='mt-3 text-right'>
                  <ButtonGroup>
                    <Button
                      className='btn-outline-danger'
                      onClick={handleHistoryBack}
                    >
                      zurück
                    </Button>

                    <Button
                      className='btn-outline-success'
                      disabled={
                        pw.length === 0 ||
                        pwn1.length === 0 ||
                        pwn2.length === 0
                      }
                      onClick={handleSubmit}
                    >
                      speichern
                    </Button>
                  </ButtonGroup>
                </FormGroup>
              </form>
            </div>
          </div>
        </div>
      </LoginLayout>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.profile.loading,
  error: state.profile.error,
  messages: state.profile.messages
});

const mapDispatchToProps = dispatch => ({
  edit: () => dispatch(profileActions.edit()),
  changepassword: (pw, pwn1, pwn2) =>
    dispatch(profileActions.changepassword(pw, pwn1, pwn2))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileRoute);
