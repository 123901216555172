import React from 'react';
import PropTypes from 'prop-types';
import { Label, UncontrolledTooltip } from 'reactstrap';

/**
 * @param string name Defines the name
 * @param string title The title
 * @param string tooltip Tooltip that should be displayed
 *
 * @returns {*}
 * @constructor
 */
export const RowLabel = ({
                           name,
                           title,
                           tooltip = null,
                           lg = 4,
                           xl = 3
                         }) => {

  return (
    <Label lg={lg} xl={xl} className={'text-one text-uppercase font-weight-bold'}>
      {title}

      {tooltip !== null && (
        <>
          <i
            id={`tt_${name}`}
            className='far fa-question-circle text-one pointer pl-1'
            style={{ fontSize: '0.8em' }}
          />
          <UncontrolledTooltip
            target={`tt_${name}`}
            delay={{ hide: 500 }}
            autohide={false}
          >
            {tooltip}
          </UncontrolledTooltip>
        </>
      )}
    </Label>
  );
};

RowLabel.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string
};
