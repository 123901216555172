import React from 'react';
import { connect } from 'react-redux';
import HeaderIcon from './HeaderIcon';
import Navigation from '../containers/Navigation';

const Header = props => (
  <div className="container-fluid align-self-start no-gutters p-0">
    <nav className="navbar navbar-expand-sm navbar-dark bg-header">
      {/*mindline icon*/}
      <HeaderIcon />

      {/*main menu*/}
      {props.authentication.loggedIn && <Navigation />}
    </nav>
  </div>
);

const mapStateToProps = state => ({
  authentication: state.authentication
});

export default connect(
  mapStateToProps,
  null
)(Header);
