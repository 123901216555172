import React, { useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { settingsActions } from '../../../core/actions';

import { BaseHeaderLogo } from './';

import GermanFlag from '../../assets/img/country/de_DE.png';
import USFlag from '../../assets/img/country/en_US.png';

const navLinks = [
  {
    to: '/one/',
    exact: true,
    activeClassName: 'active',
    messageId: 'smoove.menu.item.dashboard',
    defaultMessage: 'Overview'
  },
  {
    to: '/one/projects',
    exact: true,
    activeClassName: 'active',
    messageId: 'smoove.menu.item.projects',
    defaultMessage: 'Projects'
  },
  {
    to: '/one/reports',
    exact: true,
    activeClassName: 'active',
    messageId: 'smoove.menu.item.reports',
    defaultMessage: 'Reports'
  },
  {
    to: '/one/settings',
    exact: true,
    activeClassName: 'active',
    messageId: 'smoove.menu.item.settings',
    defaultMessage: 'Settings'
  },
  {
    to: '/one/help',
    exact: true,
    activeClassName: 'active',
    messageId: 'smoove.menu.item.help',
    defaultMessage: 'Help'
  }
];

export const BaseHeader = ({ setLocale, locale }) => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerRef = useRef();

  const handleScroll = () => {
    if (window.pageYOffset > 30) {
      headerRef.current.classList.add('shrinked');
      headerRef.current.classList.add('box-shadow');
    } else {
      headerRef.current.classList.remove('shrinked');
      headerRef.current.classList.remove('box-shadow');
    }
  };

  return (
    <div
      className='base-header d-flex container-fluid fixed-top'
      ref={headerRef}
    >
      <nav className='navbar navbar-expand-sm flex-fill p-0'>
        {/* Logo */}
        <Link className='navbar-brand' to={`/`}>
          <BaseHeaderLogo animate={true} />
        </Link>

        <div className='d-flex collapse navbar-collapse h-100'>
          {/* Main Menu */}
          <ul className={'navbar-nav mr-auto h-100 align-items-center'}>
            {navLinks.map(({ messageId, defaultMessage, ...rest }) => (
              <NavLink
                {...rest}
                key={rest.to}
                className={'menu-item text-uppercase text-one h-100'}
              >
                <FormattedMessage
                  id={messageId}
                  defaultMessage={defaultMessage}
                />
              </NavLink>
            ))}
          </ul>

          {/* User Dropdown Menu */}
          <ul className={'navbar-nav'}>
            <li className='menu-item no-underline dropdown pointer'>
              <span
                className={'nav-link text-uppercase text-one-red'}
                id='navbarDropdown'
                role='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                Stefan <i className='fas fa-chevron-down' />
              </span>

              <div
                className='dropdown-menu dropdown-menu-right'
                aria-labelledby='navbarDropdown'
              >
                <NavLink
                  to={'/one/profile'}
                  exact={true}
                  className='dropdown-item'
                  activeClassName='active'
                >
                  <FormattedMessage
                    id={'core.menu.item.profile'}
                    defaultMessage={'Profile'}
                  />
                </NavLink>

                <div className='dropleft dropdown-item dropdown-hover'>
                  <div
                    className='dropdown-toggle'
                    id='navbarDropdownLanguage'
                    role={'button'}
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <FormattedMessage
                      id={'core.menu.item.language'}
                      defaultMessage={'Language'}
                    />
                  </div>

                  <div
                    className='dropdown-menu'
                    aria-labelledby='navbarDropdownLanguage'
                  >
                    <div
                      className={`dropdown-item ${
                        locale === 'de-DE' ? 'active' : ''
                      }`}
                      onClick={() => setLocale('de-DE')}
                    >
                      <img src={GermanFlag} height='35px' alt={'Deutsch'} />
                      German
                    </div>
                    <div
                      className={`dropdown-item ${
                        locale === 'en-US' ? 'active' : ''
                      }`}
                      onClick={() => setLocale('en-US')}
                    >
                      <img src={USFlag} height='35px' alt={'English'} />
                      English
                    </div>
                  </div>
                </div>

                <div className='dropdown-divider' />
                <NavLink
                  to={'/logout'}
                  exact={true}
                  className='dropdown-item'
                  activeClassName='active'
                >
                  <FormattedMessage
                    id={'core.menu.item.logout'}
                    defaultMessage={'Logout'}
                  />
                </NavLink>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

BaseHeader.propTypes = {};

const mapStateToProps = state => ({
  locale: state.settings.locale
});

const mapDispatchToProps = dispatch => ({
  setLocale: locale => dispatch(settingsActions.setLocale(locale))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseHeader);
