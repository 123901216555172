import { persistReducer } from 'redux-persist';
import local from 'redux-persist/lib/storage';
import { settingsConstants, userConstants } from '../constants';

const INITIAL_STATE = {
  remember: false,
  locale: 'en-US'
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return { ...state, remember: action.user.remember };

    case userConstants.LOGOUT_SUCCESS:
      return { ...state, remember: false };

    case settingsConstants.SETTINGS_SET_LOCALE:
      return { ...state, locale: action.payload };

    default:
      return { ...state };
  }
};

const persistConfig = {
  key: 'settings',
  storage: local
};

export const settings = persistReducer(persistConfig, settingsReducer);
