import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export const BaseFooter = () => {
  return (
    <div className='base-footer-container container-fluid d-flex justify-content-between align-items-center'>
      <div className='base-footer container-fluid d-flex justify-content-between align-items-center'>
        <div className={'test'}>&copy; 2019 mindline analytics</div>

        <div>
          <Link to={'/imprint/'}>
            <FormattedMessage
              id={'core.link.label.imprint'}
              defaultMessage={'Imprint'}
            />
          </Link>
          {' | '}
          <Link to={'/gdpr/'}>
            <FormattedMessage
              id={'core.link.label.gdpr'}
              defaultMessage={'Data Protection & Privacy'}
            />
          </Link>
          {' | '}
          <Link to={'/gtc/'}>
            <FormattedMessage
              id={'core.link.label.gtc'}
              defaultMessage={'Terms & Conditions'}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
