import React from 'react';
import '../../assets/css/fonts/font-face.css';
import '../../assets/css/base_layout.css';

import { BaseHeaderPublic, BaseHeader, BaseFooter } from './';

export const BaseLayout = ({ children, authentication }) => {
  return (
    <>
      {!authentication || authentication.loggedIn ? (
        <BaseHeader />
      ) : (
        <BaseHeaderPublic />
      )}
      {children}
      <BaseFooter />
    </>
  );
};
