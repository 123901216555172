import React from 'react';
import { NavLink } from 'react-router-dom';
import { AccountButton } from '../../common/components/buttons';

const links = (navigate, routes) => {
  if (routes.routes && routes.routes.length > 0) {
    const visibleRoutes = routes.routes.filter(item => {
      return item.visible !== false;
    });

    return visibleRoutes.map(({ name, link, path, exact }) => (
      <li key={name} className='nav-item'>
        <NavLink
          to={link ? link : path}
          exact={exact}
          className='nav-link'
          activeClassName='active'
        >
          {name}
        </NavLink>
      </li>
    ));
  } else {
    return;
  }
};

export default ({ navigate, match, routes, logout, access }) => {
  const isEditor =
    access.hasOwnProperty('DatevEva') &&
    access.DatevEva.hasOwnProperty('XSurvey') &&
    access.DatevEva.XSurvey.hasOwnProperty('savelist');

  const isClientAdmin =
    access.hasOwnProperty('DatevEva') &&
    access.DatevEva.hasOwnProperty('XSurvey') &&
    access.DatevEva.XSurvey.hasOwnProperty('new');

  // const isSuperAdmin =
  //   access.hasOwnProperty('Admin') &&
  //   access.Admin.hasOwnProperty('Client') &&
  //   access.Admin.Client.hasOwnProperty('new');

  //@Todo: Show only programs relevant to the user (e.g. Administration only for admins/ client moderators)

  let programs = [
    <NavLink
      to={'/datev'}
      exact
      className='nav-link dropdown-item text-dark'
      key={'EVA Assistent'}
    >
      EVA Assistent
    </NavLink>,
    <NavLink
      to={'/one/'}
      exact
      className='nav-link dropdown-item text-dark'
      key={'mindline ONE'}
    >
      mindline ONE
    </NavLink>,
    <NavLink
      to={'/admin'}
      exact
      className='nav-link dropdown-item text-dark'
      key={'Administration'}
    >
      Administration
    </NavLink>
  ];

  if (!isClientAdmin) {
    // if (!isSuperAdmin) {
    programs = programs.filter(program => program.key !== 'Administration');
  }

  if (!isEditor) {
    routes.routes = routes.routes.filter(
      route => route.key !== 'WavesComponent'
    );
  }

  const activeProgram = programs.find(
    program => program.props.to === match.path
  );
  const otherPrograms = programs.filter(
    program => program.props.to !== match.path
  );

  return (
    <React.Fragment>
      {/*navbar toggler*/}
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarNav'
        aria-controls='navbarNav'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon' />
      </button>

      {/*dynamic main menu icons*/}
      <div className='collapse navbar-collapse' id='navbarNav'>
        <ul className='navbar-nav mr-auto'>
          {otherPrograms.length > 0 && (
            <li className='nav-item dropdown'>
              <div
                className='nav-link dropdown-toggle pointer'
                href='#'
                id='navbarDropdownMenuLink'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
                title={'Change Program'}
              >
                {activeProgram
                  ? activeProgram.props.children
                  : 'select program'}
              </div>
              <div
                className='dropdown-menu'
                aria-labelledby='navbarDropdownMenuLink'
              >
                {otherPrograms}
              </div>
            </li>
          )}

          {/*<NavLink to={'/datev'} exact className="nav-link">EVA Assistent</NavLink>*/}
          {/*<NavLink to={'/admin'} exact className="nav-link">Administration</NavLink>*/}

          {links(navigate, routes)}
        </ul>
        <ul className='navbar-nav align-content-end'>
          <li className='nav-link pointer' onClick={() => logout()}>
            Logout
          </li>
          <NavLink to={'/profile'} title={'Profil-Einstellungen'}>
            <AccountButton className='btn-outline-light' />
          </NavLink>
        </ul>
      </div>
    </React.Fragment>
  );
};
