import React from 'react';
import { Link } from 'react-router-dom';
// import HeaderIcon from './HeaderIcon';

const Footer = () => (
  <div className="container-fluid w-100 bg-dark align-self-end">
    <span className="text-white-50 float-right">
      <Link className={'text-white-50'} to={'/imprint'}>
        © 2019 mindline analytics - Imprint
      </Link>
    </span>
  </div>
);

export default Footer;
