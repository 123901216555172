import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { BaseLayout } from '../../common/components/layouts';

import { userActions } from '../actions';
import Stone from '../../common/components/layouts/Stone';

import '../../common/assets/css/forms.css';
import LoginIcon from '../../common/assets/img/icons/login.png';

const LoginRoute = ({ dispatch, history, authentication, settings }) => {
  const [state, setState] = useState({
    username: '',
    password: '',
    captcha: '',
    remember: false,
    submitted: false
  });
  const intl = useIntl();

  useEffect(() => {
    if (authentication.loggedIn === true) {
      history.push('/');
    } else if (
      authentication.loggingIn === false &&
      settings.remember === true
    ) {
      userActions.checkSession()(dispatch);
    }
  }, []);

  const handleChange = e => {
    let { name, value, type } = e.target;

    if (type === 'checkbox') {
      value = e.target.checked;
    }

    setState({ ...state, [name]: value, submitted: false });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setState({ ...state, submitted: true });

    /**
     * @Todo Add check for captcha (authentication.captcha === true => captcha must be entered, otherwise display message
     */
    if (state.username && state.password) {
      dispatch(
        userActions.login({
          username: state.username,
          password: state.password,
          captcha: state.captcha.length > 0 ? state.captcha : false,
          remember: state.remember
        })
      );
    }
  };

  return (
    <BaseLayout authentication>
      <Stone
        type={'D'}
        color={'green'}
        width={'400px'}
        position={['20%', null, null, 0]}
        translate={[-250, 0]}
      />
      <Stone
        type={'B'}
        color={'orange'}
        width={'400px'}
        position={['50%', 0]}
        translate={[90, 0]}
      />
      <Stone
        type={'A'}
        color={'blue'}
        width={'500px'}
        position={[null, null, 0, '50%', 'translateX(-50%)']}
        translate={[0, 300]}
      />

      <div className='base-content d-flex flex-fill container-fluid position-relative'>
        <div className={'container-fluid p-0'} style={{ maxWidth: 800 }}>
          {/* Login Icon */}
          <img
            src={LoginIcon}
            style={{ position: 'absolute', transform: 'translate(-150px,0px)' }}
            alt=''
          />

          {/* Headline */}
          <h2 className='text-uppercase text-one-red font-weight-bold'>
            <FormattedMessage
              id={'core.login.headline'}
              defaultMessage={'Anmelden'}
            />
          </h2>

          {authentication.error ? <span>{authentication.error}</span> : ''}

          <form onSubmit={handleSubmit}>
            <div className={'form-group'}>
              <h6 className={'text-one text-uppercase font-weight-bold pt-5'}>
                <FormattedMessage
                  id={'core.login.label.user'}
                  defaultMessage={'Email'}
                />
              </h6>
              <input
                id={'username'}
                type={'text'}
                name={'username'}
                autoComplete='off'
                className={'question'}
                placeholder={intl.formatMessage({
                  id: 'core.login.placeholder.user'
                })}
                value={state.username}
                onChange={handleChange}
                required
                autoFocus
              />
              <label>
                <span />
              </label>
            </div>

            <div className={'form-group mt-3'}>
              <h6 className={'text-one text-uppercase font-weight-bold pt-4'}>
                <FormattedMessage
                  id={'core.login.label.password'}
                  defaultMessage={'Password'}
                />
              </h6>
              <input
                id={'password'}
                type={'password'}
                name={'password'}
                autoComplete='off'
                className={'question'}
                placeholder={intl.formatMessage({
                  id: 'core.login.placeholder.password'
                })}
                value={state.password}
                onChange={handleChange}
                required
              />
              <label htmlFor='password'>
                <span />
              </label>
            </div>

            {authentication.captcha && (
              <div className={'form-group'}>
                <h6 className={'text-one text-uppercase font-weight-bold pt-5'}>
                  Security Characters
                </h6>
                <div className='form-row'>
                  <div className='col'>
                    <input
                      id={'captcha'}
                      type={'text'}
                      name={'captcha'}
                      autoComplete='off'
                      className={'question'}
                      placeholder={'enter characters...'}
                      value={state.captcha}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor='captcha'>
                      <span />
                    </label>

                    {state.submitted && !state.captcha && (
                      <div className='invalid-feedback'>
                        <FormattedMessage
                          id={'core.login.error.captcha-required'}
                        />
                      </div>
                    )}
                  </div>

                  <div className='col'>
                    <img src='/captcha.php' alt='' />
                  </div>

                  <div className='col' />
                </div>
              </div>
            )}

            <div className='custom-control custom-switch'>
              <input
                type='checkbox'
                name='remember'
                className='custom-control-input'
                id='customSwitch1'
                onChange={handleChange}
              />
              <label className='custom-control-label' htmlFor='customSwitch1'>
                <FormattedMessage id={'core.login.label.remember'} />
              </label>
            </div>

            <button
              className='btn btn-one mt-4'
              type='submit'
              style={{ minWidth: 250 }}
            >
              {authentication.loggingIn === true ? (
                <>
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  />{' '}
                  loading
                </>
              ) : (
                <FormattedMessage id={'core.login.button.submit'} />
              )}
            </button>
          </form>
        </div>
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = state => ({
  authentication: state.authentication,
  settings: state.settings,
  alert: state.alert
});

const connectedRoute = connect(
  mapStateToProps,
  null
)(LoginRoute);

export default connectedRoute;
