import axios from 'axios';
// import { history } from '../store';
import { profileConstants } from '../constants';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 30000;

const edit = () => {
  return dispatch => {
    dispatch({ type: profileConstants.EDIT_USER_REQUEST });

    const pca = {
      program: 'User',
      controller: 'XUser',
      action: 'edit'
    };

    return axios
      .get(process.env.REACT_APP_API, { params: pca })
      .then(editResponse => {
        const user = editResponse.data.result.program.User.XUser.edit.data;
        dispatch({ type: profileConstants.EDIT_USER_SUCCESS, payload: user });
      })
      .catch(err => {
        dispatch({
          type: profileConstants.EDIT_USER_FAILURE,
          payload: 'Something went wrong while fetching user edit data'
        });
      });
  };
};

const save = () => {};

const changepassword = (pw, pwn1, pwn2) => {
  console.log(pw, pwn1, pwn2);
  return dispatch => {
    dispatch({ type: profileConstants.CHANGE_PASSWORD_REQUEST });

    const pca = {
      program: 'User',
      controller: 'XUser',
      action: 'changepassword'
    };

    const fd = new FormData();
    fd.append('pw', pw);
    fd.append('pw1', pwn1);
    fd.append('pw2', pwn2);

    return axios.post(process.env.REACT_APP_API, fd, { params: pca }).then(
      editResponse => {
        const actionResult =
          editResponse.data.result.program.User.XUser.changepassword;

        if (actionResult.status === true) {
          dispatch({
            type: profileConstants.CHANGE_PASSWORD_SUCCESS,
            payload: [
              {
                type: 'success',
                message: 'Änderung gespeichert!'
              }
            ]
          });
        } else {
          let errors = {};

          for (const dataKey in actionResult.data) {
            if (actionResult.data[dataKey].hasOwnProperty('errors')) {
              errors[dataKey] = { ...actionResult.data[dataKey].errors };
            }
          }
          dispatch({
            type: profileConstants.CHANGE_PASSWORD_FAILURE,
            payload: errors
          });
          return Promise.reject('failed');
        }
      },
      error => {
        console.log('lala', error);
      }
    );
  };
};

export const profileActions = {
  edit,
  save,
  changepassword
};
