import React from 'react';
import PropTypes from 'prop-types';
import { Col, CustomInput, FormGroup } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { RowLabel } from './';

export const MultichoiceRow = props => {
  const {
    title,
    name,
    value,
    placeholder = null,
    tooltip = null,
    required = false
  } = props;

  return (
    <FormGroup row>
      <RowLabel title={title} tooltip={tooltip} />

      <Col lg={8} xl={9}>
        <CustomInput
          id={name}
          type={'text'}
          name={name}
          autoComplete='off'
          className={'question'}
          placeholder={placeholder || false}
          value={value || null}
          onChange={({ target }) => {
            console.log(target);
          }}
          required={required}
        />
        <label htmlFor='name'>
          <span />
        </label>
      </Col>
    </FormGroup>
  );
};

MultichoiceRow.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  required: PropTypes.bool
};
