import React from 'react';
import { FormGroup } from './FormGroup';
import PropTypes from 'prop-types';

export const FormGroupInputText = props => (
  <FormGroup className={props.fgClassName} style={props.style}>
    <label
      htmlFor={props.name}
      className={props.style === 'row' ? 'col-2 col-form-label' : ''}
    >
      {props.label}
    </label>

    <div className="col">
      <input
        type={props.type ? props.type : 'text'}
        className={
          'form-control' +
          (props.submitted && (!props.value || props.invalidText)
            ? ' is-invalid'
            : '')
        }
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChangeFn}
        value={props.value ? props.value : ''}
        autoComplete={props.autocomplete ? props.autocomplete : 'off'}
      />
      {props.submitted && (!props.value || props.invalidText) && (
        <div className="invalid-feedback">{props.invalidText}</div>
      )}
    </div>
  </FormGroup>
);

FormGroupInputText.propTypes = {
  submitted: PropTypes.bool
};
