import React from 'react';
import { LoginLayout } from '../pages/LoginLayout';
import { Redirect } from 'react-router';

const HomeRoute = () => (
  <LoginLayout>
    <Redirect to='/one' />
  </LoginLayout>
);

export default HomeRoute;
