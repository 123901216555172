import React, { useState } from 'react';
import Login from '../routes/login';
import Imprint from '../routes/imprint';
import Home from '../routes/home';
import { Switch, Route } from 'react-router-dom';
import DynamicImport from '../containers/DynamicImport';
import ProfileRoute from '../routes/profile';

import { Logout } from '../pages';

import PrivateRoute from '../containers/PrivateRoute';
import { history } from '../store';
import { ConnectedRouter } from 'connected-react-router';
import { IntlProvider } from 'react-intl';
import { i18n } from '../../core/translation';

const Datev = props => (
  <DynamicImport
    load={() =>
      import(/* webpackChunkName: "datev" */ '../../modules/datev_eva')
    }
  >
    {Component =>
      Component === null ? <div>loading...</div> : <Component {...props} />
    }
  </DynamicImport>
);

const One = props => (
  <DynamicImport
    mergeMessages={props.mergeMessages}
    load={() => import(/* webpackChunkName: "one" */ '../../modules/smoove')}
  >
    {Component =>
      Component === null ? <div>loading...</div> : <Component {...props} />
    }
  </DynamicImport>
);

const Admin = props => (
  <DynamicImport
    load={() => import(/* webpackChunkName: "admin" */ '../../modules/admin')}
  >
    {Component =>
      Component === null ? <div>loading...</div> : <Component {...props} />
    }
  </DynamicImport>
);

const BaseLayout = props => {
  const [messages, setMessages] = useState(i18n.messages);

  const mergeMessages = newMessages => {
    setMessages(i18n.registerMessages(newMessages));
  };

  return (
    <IntlProvider
      locale={props.locale}
      defaultLocale={'en-US'}
      messages={messages[props.locale]}
    >
      <ConnectedRouter history={history}>
        <Switch>
          <Route path={'/login'} exact component={Login} />
          <Route path={'/logout'} exact component={Logout} />

          <PrivateRoute exact path={'/'} component={Home} />
          <PrivateRoute path={'/profile'} exact component={ProfileRoute} />
          <PrivateRoute path={'/admin'} component={Admin} />
          <PrivateRoute path={'/datev'} component={Datev} />
          <PrivateRoute
            path={'/one'}
            component={One}
            mergeMessages={mergeMessages}
          />
          <Route path={'/imprint'} exact component={Imprint} />
        </Switch>
      </ConnectedRouter>
    </IntlProvider>
  );
};

export { BaseLayout };
