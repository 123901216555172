import { Component } from 'react';
import { routeActions } from '../actions/route.actions';
import { store } from '../store';

const injectRoutes = (routes, store) => {
  store.dispatch(routeActions.add(routes));
};

// const DynamicImport = (props) => {
//   const [loadedComponent, setLoadedComponent] = useState(null);
//   const [loadedRoutes, setLoadedRoutes] = useState(null);
//
//   const { load, children } = props;
//
//   useEffect(() => {
//     load().then(loadedModule => {
//         const { routes, component, reducers, messages } = loadedModule;
//
//         if (reducers) {
//           reducers.forEach(reducer => {
//             store.injectReducers(
//               store,
//               reducer.name,
//               reducer.fn
//             );
//           });
//         }
//
//         if (routes) {
//           injectRoutes(routes, store);
//         }
//
//         if (messages) {
//           i18n.registerMessages(messages);
//         }
//
//         console.log(i18n.messages['de-DE']['one.menu.item.dashboard']);
//
//         setLoadedComponent(component ? component : loadedModule.default);
//         setLoadedRoutes(routes ? routes : []);
//       })
//       .catch(error => console.log(error.message));
//
//     return () => {
//       console.log('useEffect unmount');
//     }
//   }, []);
//
//   return children( () => loadedComponent );
// };
//
// export default DynamicImport;

export default class DynamicImport extends Component {
  state = {
    component: null,
    routes: null
  };

  componentDidMount() {
    const { load } = this.props;

    load()
      .then(loadedModule => {
        const { routes, component, reducers, messages } = loadedModule;

        if (reducers) {
          reducers.forEach(reducer => {
            store.injectReducers(store, reducer.name, reducer.fn);
          });
        }

        if (routes) {
          injectRoutes(routes, store);
        }

        if (messages) {
          this.props.mergeMessages(messages);
        }

        this.setState({
          component: component ? component : loadedModule.default,
          routes: routes ? routes : []
        });
      })
      .catch(error => console.log(error.message));
  }

  render() {
    return this.props.children(this.state.component);
  }
}
