import axios from 'axios';
import { history } from '../store';
import { userConstants } from '../constants';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 30000;

export const userActions = {
  login,
  logout,
  checkSession,
  recover
};

function checkSession() {
  return dispatch => {
    const data = {
      program: 'User',
      controller: 'XIndex',
      action: 'home'
    };

    dispatch(request());

    return axios
      .get(process.env.REACT_APP_API, { params: data })
      .then(loginResult => {
        dispatch(success(loginResult.data.result));
        history.push('/');
      })
      .catch(() => {
        dispatch(failure());
      });
  };

  function request() {
    return { type: userConstants.REMEMBER_LOGIN_REQUEST };
  }

  function success(user) {
    return { type: userConstants.REMEMBER_LOGIN_SUCCESS, user };
  }

  function failure() {
    return { type: userConstants.REMEMBER_LOGIN_FAILURE };
  }
}

function login(data) {
  const { username, password, captcha, remember } = data;

  return dispatch => {
    dispatch(request({ username }));

    const pca = {
      program: 'User',
      controller: 'XIndex',
      action: 'login'
    };

    const fd = new FormData();

    fd.append('username', username);
    fd.append('pw', password);

    if (captcha) {
      fd.append('captcha', captcha);
    }
    if (remember) {
      fd.append('rememberme', remember);
    }

    //Todo: Handle error response
    return axios
      .post(process.env.REACT_APP_API, fd, { params: pca })
      .then(loginResult => {
        if (
          (
            loginResult.data.result.program.User.XIndex.login.hasOwnProperty('status') &&
            loginResult.data.result.program.User.XIndex.login.status === true )

          //@deprecated: CHECK LOGIN SUCCESS FOR OLD TOOLBOX VERSION
          || (
            loginResult.data.result.program.User.XIndex.login.hasOwnProperty('auth') &&
            loginResult.data.result.program.User.XIndex.login.auth === true
          )
        ) {
          loginResult.data.result.remember = remember === true;
          dispatch(success(loginResult.data.result));
          history.push('/');
        } else {
          if (
            loginResult.data.result.program.User.XIndex.login.hasOwnProperty(
              'params'
            ) &&
            loginResult.data.result.program.User.XIndex.login.params.hasOwnProperty(
              'captcha'
            )
          ) {
            dispatch(require_captcha());
          }

          if (
            loginResult.data.result.messages.length > 0 &&
            loginResult.data.result.messages[0].tag === 'error'
          ) {
            dispatch(failure(loginResult.data.result.messages[0].message));
          }
        }
      })
      .catch(() => {
        const message =
          `An error has occurred, please try again. ` +
          `If the error occurs again, please contact your account administrator.`;
        dispatch(failure(message));
      });
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function require_captcha() {
    return { type: userConstants.LOGIN_CAPTCHA_REQUIRED };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return dispatch => {
    dispatch(request());

    const pca = {
      program: 'User',
      controller: 'XIndex',
      action: 'logout'
    };

    return axios
      .post(process.env.REACT_APP_API, null, { params: pca })
      .then(res => {
        if (res.status === 200) {
          dispatch(success(res.data.result.messages[0]));
        } else {
          throw Error(`Request failed with status code ${res.status}`);
        }
      })
      .catch(err => {
        dispatch(failure(err));
      });
  };

  function request() {
    return { type: userConstants.LOGOUT_REQUEST };
  }
  function success(payload) {
    return { type: userConstants.LOGOUT_SUCCESS, payload };
  }
  function failure(error) {
    return { type: userConstants.LOGOUT_FAILURE, error };
  }
}

function recover(username, email, captcha) {
  console.log(username, email, captcha);

  return dispatch => {
    dispatch(request({ username, email }));

    const pca = {
      program: 'User',
      controller: 'XIndex',
      action: 'recover'
    };

    const fd = new FormData();

    fd.append('username', username);
    fd.append('email', email);
    fd.append('captcha', captcha);
    fd.append('pw', '');

    axios
      .post(process.env.REACT_APP_API, fd, { params: pca })
      .then(recoverResult => {
        // history.push('/');
        dispatch(success(recoverResult.data));
      })
      .catch(err => {
        dispatch(failure(err));
      });
  };

  function request(user) {
    return { type: userConstants.LOGOUT_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGOUT_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGOUT_FAILURE, error };
  }
}
