import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { coreReducers } from './reducers';
import { connectRouter } from 'connected-react-router';
import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

const createRootReducer = (history, asyncReducers) =>
  combineReducers({
    router: connectRouter(history),
    ...coreReducers,
    ...asyncReducers
  });

const middleware = [thunkMiddleware, routerMiddleware(history)];

export const store = (() => {
  const store = createStore(
    createRootReducer(history),
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 }),
    applyMiddleware(...middleware)
  );

  store.asyncReducers = {};

  store.injectReducers = (store, name, asyncReducer) => {
    store.asyncReducers = { ...store.asyncReducers, [name]: asyncReducer };
    store.replaceReducer(createRootReducer(history, store.asyncReducers));
  };

  store.persistor = persistStore(store);

  return store;
})();
