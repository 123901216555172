import React from 'react';

export const FormGroup = props => {
  let className = 'form-group';
  className += props.className ? ' ' + props.className : '';

  if (props.style === 'row') {
    className += ' row';
  }

  return <div className={className}>{props.children}</div>;
};
