/**
 *
 * PrivateRoute
 * Higher Order Component that blocks navigation when the user is not logged in
 * and redirect the user to login page
 *
 * Wrap your protected routes to secure your containers
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  authentication,
  mergeMessages,
  ...props
}) => {
  /**
   * user is authenticated, render component
   */
  if (authentication.loggedIn) {
    return (
      <Route
        render={props => <Component {...props} mergeMessages={mergeMessages} />}
        {...props}
      />
    );

    /**
     * user is not authenticated, redirect to login
     */
  } else {
    return (
      <Route
        render={props => (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )}
      />
    );
  }
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  authentication: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  authentication: state.authentication
});

export default connect(
  mapStateToProps,
  null
)(PrivateRoute);
