import { settingsConstants } from '../constants';

const setLocale = locale => {
  return {
    type: settingsConstants.SETTINGS_SET_LOCALE,
    payload: locale
  };
};

export const settingsActions = {
  setLocale
};
